import Navbar from "../components/Navbar";
import NavBarMobile from "../components/NavBarMobile";
import CmsPage from "../components/CmsPage";

import { loadPage } from "../lib/api-lib";

function Page({ structure }) {
  return (
    <div>
      <Navbar navigation={structure.navigation} />
      <NavBarMobile navigation={structure.navigation} />
      {typeof structure.page !== 'undefined' ? 
        <CmsPage structure={structure} />
      : ''}
    </div>
  );
}

export async function getStaticProps() {
  const data = await loadPage(); // homepage
  return {
    props: {
      structure: data,
    },
    revalidate: 1,
  };
}

export default Page;
